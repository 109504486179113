const config = {
 
  apiUrl: 'https://pixelwebsite-server.onrender.com/', // API base URL
  uploadUrl: 'https://pixelwebsite-server.onrender.com/uploads/' // Uploads folder URL
    // apiUrl: 'https://pixel-admin-server.vercel.app/', // API base URL
    // uploadUrl: 'https://pixel-admin-server.vercel.app/uploads/' // Uploads folder URL

    // apiUrl: 'http://localhost:5000/', // API base URL
    // uploadUrl: 'http://localhost:5000/' // Uploads folder URL
  };
  
  export default config;
  
